.paperknife-header {
    background-image: url("./../images/bg/paperknife_landing_mobile.png");
    min-height: 600px;
    padding: 80px 0px 90px 8px;

    @include media-breakpoint-up(md) {
        background-image: url("./../images/bg/paperknife_landing_desktop.png");
        padding: 146px 0px 90px 0px;
    }
}

.adoreme-use-case-header {
    background-image: url("./../images/bg/header_adoreme_use_case_mobile.jpg");
    min-height: 600px;
    padding: 80px 0px 90px 8px;

    @include media-breakpoint-up(md) {
        background-image: url("./../images/bg/header_adoreme_use_case_desktop.jpg");
        padding: 146px 0px 90px 0px;
    }
}

.navbar-brand img {
    width: 120px;
    height: 55px;
}

.section-features-tabs ul li a.active h6 {
    color: $color-primary;
}

.pricing-1 .price {
    font-size: 30px;
}

body {
    font-size: 1.2rem !important;

    @include media-breakpoint-up(md) {
        font-size: 1rem !important;
    }

    color: #000;
}

html {
    font-size: 14px;
}

.lead {
    font-weight: 500;
}
